<template>
  <div class="item">
    <el-col :span="9">
       <el-row class="ShopImg">
            <el-col :span="7" @click="jumpDetail(goodsDetails)">  <img src="@/assets/conpon.jpg" alt="" /> </el-col>
            <!-- <div class="leftContent"   @click="jumpDetail(goodsDetails)">
                
            </div> -->
            <div class="otherInfo">
                  <h3>{{ goodsDetails.couponName }} 
                      <span class="gift">[赠品]</span>
                  </h3>
                <el-col v-if="goodsDetails.useCondition==0">
                    <span>全场商品可用</span>
                </el-col>
                <el-col  v-if="goodsDetails.useCondition==1">
                    <span>满{{ goodsDetails.useAmountCondition }}元可用</span>
                </el-col>
                <el-col v-if="goodsDetails.useCondition==2">
                    <span>满{{ goodsDetails.useNumCondition }}个品种可用</span>
                </el-col>
                <el-col class="dateMsg" >
                    <span>开始时间：{{goodsDetails.validStartTime.substring(0,10)}}</span>
                </el-col>
                <el-col class="dateMsg" >
                    <span>结束时间：{{goodsDetails.validEndTime.substring(0,10)}}</span>
                </el-col>
                
            </div>
        </el-row>
    </el-col>
    <el-col :span="3" class="text-center goodsBox"  v-if="goodsDetails.promotionMethod==0">
       ￥{{ goodsDetails.promotionValue }}
    </el-col>
    <el-col :span="3" class="text-center goodsBox"  v-if="goodsDetails.promotionMethod==1">
       {{ goodsDetails.promotionValue }}折
    </el-col>

    <el-col :span="4" class="text-center goodsBox">{{
      goodsDetails.tmpCount
    }}</el-col>
    <el-col :span="8" class="text-center goodsBox">赠品优惠券</el-col>
   
  </div>
</template>

<script>
export default {
  name: "GoodsItem",
  data() {
    return {
      indexNum:'',
      couponSelected: "",
      couponAmount:0,
      proSec:''
    };
  },
  props: {
    goodsDetails: {
      type: Object,
    },
    num:{
      type:Number
    },
    allowCoupon:{
      type:Number
    }
  },
  created() {
    console.log('123',this.goodsDetails)
    
  },
  computed: {
  
  },
  methods: {
    jumpDetail(goods) {
      console.log(goods)
    },
   
  },
};
</script>

<style lang="less" scoped>
@import "~style/index.less";
.ShopImg {
  width: 400px;
  color: #666;
  font-size: 12px;
  line-height: 17px;
  padding-left: 20px;
  position: relative;
    img {
        display: inline-block;
        width: 80px;
        height: 80px;
        padding: 5px;
        border: 1px solid #f0f0f0;
    }
    .otherInfo {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        height: 92px;
        h3 {
        color: #333;
        font-size: 13px;
        line-height: 18px;
        font-weight: 600;
        }
        .gift {
        color: #ff9999;
        }
        .GetCoupon {
        font-size: 12px;
        color: #ff3333;
        background: none;
        border: none;
        }
        /deep/.el-dialog__header {
        padding-bottom: 0;
        }
  }
}
.item {
  margin-top: 4px;
  background: rgb(255 255 255);
  border-bottom:1px solid  #f0f0f0;
  float: left;
  width:100%;
  .goodsBox {
    line-height: 80px;
    font-size: 14px;
    color: #666;
  }
  .orderPromotionsBox {
    height: 80px;
    justify-content: center; //子元素水平居中
    align-items: center; //子元素垂直居中
    display: -webkit-flex;
    .orderPromotionsMsg {
      font-size: 14px;
      color: #666;
      text-align: left;
      text-indent: 20px;
    }
  }

  /deep/.el-select {
    height: 30px;
    width: 175px;
    line-height: 30px;
    .el-input__inner {
      height: 30px;
      color: #ff3333;
      border-color: #ff3333;
    }
    .el-input__icon {
      line-height: 30px;
      color: #ff3333;
    }
  }
}
</style>
